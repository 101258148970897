import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import firebaseConfig from "./config"

const app = initializeApp(firebaseConfig())

export const auth = getAuth()

export default app

export const db = getFirestore()
const fireStoreCollections = {
  users: "users",
}

export const getUserDoc = async id => {
  const docRef = doc(db, fireStoreCollections.users, id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }
  return {}
}

export const createUserDoc = async (values, id) => {
  const docRef = doc(db, fireStoreCollections.users, id)
  await setDoc(docRef, values)
}

export const updateUserDoc = async (values, id) => {
  const docRef = doc(db, fireStoreCollections.users, id)
  await updateDoc(docRef, values)
}
